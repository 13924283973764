import ReactGA from "react-ga4";
export const eventIntegration = (params) => {
  const {
    event,
    eventCategory,
    eventAction,
    eventLabel,
    TOC,
    PAGE_NO,
    epaperType,
    title,
    author,
    publication,
    edition,
    articleUrl,
    url,
    act_unique,
    event_origin,
  } = params;

  ReactGA.event(event, {
    event: event,
    category: eventCategory,
    action: eventAction,
    label: eventLabel,
    section_name: TOC,
    page_no: PAGE_NO?.toString(),
    view_type: epaperType,
    article_title: title,
    article_author: author,
    publication_name: publication,
    edition_name: edition,
    article_url: articleUrl,
    event_origin: "epaper",
  });

  window.clevertap &&
    window.clevertap.event.push(event, {
      // eventCategory,
      // eventAction,
      // eventLabel,
      event: event,
      category: eventCategory,
      action: eventAction,
      label: eventLabel,
      section_name: TOC,
      page_no: PAGE_NO?.toString(),
      view_type: epaperType,
      article_title: title,
      article_author: author,
      publication_name: publication,
      edition_name: edition,
      article_url: articleUrl,
      url: url,
      act_unique: act_unique,
      event_origin: "epaper",
    });
};

export const profilePush = (profileInfo, type) => {
  if (process.env.NEXT_PUBLIC_CLEAVERTAP_ID) {
    if (type === "profilepush") {
      window.clevertap.onUserLogin.push({
        Site: profileInfo,
      });
    } else if (type === "update") {
      window.clevertap.profile.push({
        Site: profileInfo,
      });
    }
  }
};
